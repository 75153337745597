import { showErrorMessage, showInfoMessage } from 'components/Helpers/Toasts';
import { mapAvailabilityData } from 'connectors/availability/Availability';
import { getFirstImage, getVariantOrMainVariantImages } from 'connectors/image/Image';
import { getUserFriendlyErrors } from 'connectors/lib/friendlyErrorMessageParser';
import { mapPayment } from 'connectors/payments/Payment';
import { mapPriceData, mapProductPriceData } from 'connectors/price/Prices';
import { mapSimpleProductApiData } from 'connectors/products/SimpleProduct';
import { mapTransport } from 'connectors/transports/Transports';
import {
    AddToCartMutationApi,
    AddToDemandCartMutationApi,
    CartFragmentApi,
    CartItemFragmentApi,
    CartItemModificationsFragmentApi,
    CartModificationsFragmentApi,
    CartPaymentModificationsFragmentApi,
    CartProductServicesModificationsFragmentApi,
    CartPromoCodeModificationsFragmentApi,
    CartQueryApi,
    CartServiceFragmentApi,
    CartServicesModificationsFragmentApi,
    CartTypeEnumApi,
    CartVoucherModificationsFragmentApi,
    DemandCartQueryApi,
    useCartQueryApi,
    useDemandCartQueryApi,
} from 'graphql/generated';
import { ApplicationErrors } from 'helpers/errors/applicationErrors';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { Translate } from 'next-translate';
import { useMemo } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { AddToCartPopupDataType, CartItemType, CartType, CurrentCartType } from 'types/cart';
import { GtmMessageOriginType } from 'types/gtm/enums';
import { CombinedError, UseQueryExecute } from 'urql';

export const useCurrentCart = (fromCache = true, isDemandCart = false, isPaused = false): CurrentCartType => {
    const { isUserLoggedIn } = useCurrentUserData();
    const { cartUuid, demandCartUuid } = usePersistStore((s) => s);
    const cartUuidValue = isDemandCart ? demandCartUuid : cartUuid;
    const { currencyCode } = useDomainConfig();
    const t = useTypedTranslationFunction();

    const [{ data: resultCartValue, fetching: cartFetching, stale: cartStale, error: cartError }, fetchCart] =
        useCartQueryApi({
            variables: { cartUuid, cartType: isDemandCart ? CartTypeEnumApi.DemandCartApi : CartTypeEnumApi.CartApi },
            pause: isPaused || (cartUuid === null && !isUserLoggedIn) || isDemandCart,
            requestPolicy: fromCache ? 'cache-first' : 'network-only',
        });

    const [
        { data: resultDemandCartValue, fetching: demandFetching, stale: demandStale, error: demandError },
        fetchDemandCart,
    ] = useDemandCartQueryApi({
        variables: { cartUuid: demandCartUuid, cartType: CartTypeEnumApi.DemandCartApi },
        pause: isPaused || (demandCartUuid === null && !isUserLoggedIn) || !isDemandCart,
        requestPolicy: fromCache ? 'cache-first' : 'network-only',
    });

    const result = isDemandCart ? resultDemandCartValue : resultCartValue;
    const fetching = isDemandCart ? demandFetching : cartFetching;
    const error = isDemandCart ? demandError : cartError;
    const stale = isDemandCart ? demandStale : cartStale;
    const fetchFunction = isDemandCart ? fetchDemandCart : fetchCart;

    return useMemo(() => {
        if (error !== undefined) {
            // EXTEND CART ERRORS HERE
            handleCartError(error, t);
        }

        if (
            result === undefined ||
            fetching ||
            (cartUuidValue === null && !isUserLoggedIn) ||
            error !== undefined ||
            result.cart === null
        ) {
            return getEmptyCart(!fetching, stale, fetchFunction);
        }

        // EXTEND CART UPDATE HERE
        const mappedCart = mapCart(result.cart, currencyCode);

        const mappedTransport =
            result.cart.transport === null ? null : mapTransport(result.cart.transport, currencyCode);
        return {
            fetchCart: fetchFunction,
            cart: mappedCart,
            isCartEmpty: mappedCart.items.length === 0,
            services: result.cart.services,
            transport: mappedTransport,
            payment: result.cart.payment === null ? null : mapPayment(result.cart.payment, currencyCode),
            paymentGoPayBankSwift: result.cart.paymentGoPayBankSwift,
            promoCode: result.cart.promoCode,
            isInitiallyLoaded: !fetching,
            isLoading: stale,
            vouchersCode: result.cart.vouchersCode,
            roundingPrice: result.cart.roundingPrice && mapPriceData(result.cart.roundingPrice, currencyCode),
            ...(isDemandCart
                ? { resultDataDemandCart: result as DemandCartQueryApi }
                : { resultDataCart: result as CartQueryApi }),
        };
    }, [cartUuidValue, currencyCode, fetchFunction, isDemandCart, isUserLoggedIn, result, error, fetching, stale, t]);
};

const getEmptyCart = (
    isInitiallyLoaded: boolean,
    isLoading: boolean,
    fetchFunction: UseQueryExecute,
): CurrentCartType => ({
    fetchCart: fetchFunction,
    cart: null,
    isCartEmpty: true,
    services: [],
    transport: null,
    payment: null,
    paymentGoPayBankSwift: null,
    promoCode: null,
    isLoading,
    isInitiallyLoaded,
    vouchersCode: null,
    roundingPrice: null,
});

export const handleCartError = (error: CombinedError, t: Translate): void => {
    const { userError, applicationError } = getUserFriendlyErrors(error, t);

    switch (applicationError?.type) {
        case ApplicationErrors['cart-not-found']:
            break;
        case ApplicationErrors.default:
            showErrorMessage(applicationError.message, GtmMessageOriginType.cart);
            break;
    }

    if (userError?.validation !== undefined) {
        for (const invalidFieldName in userError.validation) {
            showErrorMessage(userError.validation[invalidFieldName].message, GtmMessageOriginType.cart);
        }
    }
};

export const mapAddToCartPopupData = (
    addToCartResult: AddToCartMutationApi['AddToCart'] | AddToDemandCartMutationApi['AddToDemandCart'] | null,
    currencyCode: string,
): AddToCartPopupDataType | null => {
    if (addToCartResult === null) {
        return null;
    }

    return {
        ...mapSimpleProductApiData(addToCartResult.addProductResult.cartItem.product, currencyCode),
        quantity: addToCartResult.addProductResult.addedQuantity,
        isMainVariant: addToCartResult.addProductResult.cartItem.product.__typename === 'MainVariant',
        availability: mapAvailabilityData(addToCartResult.addProductResult.cartItem.product.availability),
        stockQuantity: addToCartResult.addProductResult.cartItem.product.stockQuantity,
        stockOnTheWay: addToCartResult.addProductResult.cartItem.product.stockOnTheWay,
        accessories: addToCartResult.addProductResult.cartItem.product.accessories,
    };
};

export const mapCart = (apiData: CartFragmentApi, currencyCode: string): CartType => {
    const remainingFreeTransport = apiData.remainingAmountWithVatForFreeTransport;

    return {
        items: apiData.items.map((item) => mapCartItem(item, currencyCode)),
        totalPrice: mapPriceData(apiData.totalPrice, currencyCode),
        totalItemsPrice: mapPriceData(apiData.totalItemsPrice, currencyCode),
        totalDiscountPrice: mapPriceData(apiData.totalDiscountPrice, currencyCode),
        remainingAmountWithVatForFreeTransport:
            remainingFreeTransport !== null ? Number.parseFloat(remainingFreeTransport) : null,
        promoCodeType: apiData.promoCodeType,
        vouchersPrice: apiData.vouchersPrice.map((voucherPrice) => mapPriceData(voucherPrice, currencyCode)),
        uuid: apiData.uuid,
        demandItemsUnitPricesWithoutVat: apiData.demandItemsUnitPricesWithoutVat,
        demandItemsStandardPricesWithoutVat: apiData.demandItemsStandardPricesWithoutVat,
        processor: apiData.processor,
    };
};

export const mapCartItem = (apiData: CartItemFragmentApi, currencyCode: string): CartItemType => {
    return {
        ...apiData,
        price: mapPriceData(apiData.price, currencyCode),
        product: {
            ...apiData.product,
            price: mapProductPriceData(apiData.product.price, currencyCode, apiData.product.stockUnitName),
            availability: mapAvailabilityData(apiData.product.availability),
            image: getFirstImage(
                apiData.product.__typename === 'Variant' && apiData.product.mainVariant !== null
                    ? getVariantOrMainVariantImages(apiData.product.images, apiData.product.mainVariant.images)
                    : apiData.product.images,
            ),
        },
    };
};

export const handleCartModifications = (cartModifications: CartModificationsFragmentApi, t: Translate): void => {
    handleCartServicesModifications(cartModifications.servicesModifications, t);
    handleCartPaymentModifications(cartModifications.paymentModifications, t);
    handleCartItemModifications(cartModifications.itemModifications, t);
    handleCartPromoCodeModifications(cartModifications.promoCodeModifications, t);
    handleCartVoucherModifications(cartModifications.voucherModifications, t);
    handleProductServicesModifications(cartModifications.productServicesModifications, t);
};
const handleProductServicesModifications = (
    productServicesModifications: CartProductServicesModificationsFragmentApi,
    t: Translate,
): void => {
    if (productServicesModifications.productServiceUnavailable) {
        showInfoMessage(
            t('Some of the product services you have selected are no longer available.'),
            GtmMessageOriginType.cart,
        );
    }
    if (productServicesModifications.productServicePriceChanged) {
        showInfoMessage(
            t('Price of some of the product services you have selected has changed.'),
            GtmMessageOriginType.cart,
        );
    }
};

const handleCartServicesModifications = (
    servicesModifications: CartServicesModificationsFragmentApi,
    t: Translate,
): void => {
    if (servicesModifications.serviceCannotBeSelectedForAnyProductInCart) {
        showInfoMessage(
            t('One of the services you have selected can only be selected with specific products.'),
            GtmMessageOriginType.cart,
        );
    }
    if (servicesModifications.serviceNotAllowedCombination) {
        showInfoMessage(t('The services you have selected cannot be selected together.'), GtmMessageOriginType.cart);
    }
    if (servicesModifications.serviceNotAllowedForSelectedPayment) {
        showInfoMessage(
            t('One of the services you have selected cannot be selected together with your payment method.'),
            GtmMessageOriginType.cart,
        );
    }
    if (servicesModifications.servicePricesChanged) {
        showInfoMessage(t('Price of some of the services you have selected has changed.'), GtmMessageOriginType.cart);
    }
    if (servicesModifications.serviceUnavailable) {
        showInfoMessage(
            t('Some of the services you have selected are no longer available.'),
            GtmMessageOriginType.cart,
        );
    }
};

const handleCartPaymentModifications = (
    paymentModifications: CartPaymentModificationsFragmentApi,
    t: Translate,
): void => {
    if (paymentModifications.paymentPriceChanged) {
        showInfoMessage(t('The price of the payment you selected has changed.'), GtmMessageOriginType.cart);
    }
    if (paymentModifications.paymentUnavailable) {
        showInfoMessage(t('The payment you selected is no longer available.'), GtmMessageOriginType.cart);
    }
    if (paymentModifications.paymentMaxPriceOverLimit) {
        showInfoMessage(t('Payment not available for current total price'));
    }
};

const handleCartItemModifications = (itemModifications: CartItemModificationsFragmentApi, t: Translate): void => {
    for (const cartItemWithChangedQuantity of itemModifications.cartItemsWithChangedQuantity) {
        showInfoMessage(
            t('The quantity of item {{ itemName }} has changed.', {
                itemName: cartItemWithChangedQuantity.product.fullName,
            }),
            GtmMessageOriginType.cart,
        );
    }
    for (const cartItemWithModifiedPrice of itemModifications.cartItemsWithModifiedPrice) {
        showInfoMessage(
            t('The price of item {{ itemName }} has changed.', {
                itemName: cartItemWithModifiedPrice.product.fullName,
            }),
            GtmMessageOriginType.cart,
        );
    }
    for (const soldOutCartItem of itemModifications.noLongerAvailableCartItemsDueToQuantity) {
        showInfoMessage(
            t('Item {{ itemName }} has been sold out.', { itemName: soldOutCartItem.product.fullName }),
            GtmMessageOriginType.cart,
        );
    }
    for (const nonListableCartItem of itemModifications.noLongerListableCartItems) {
        showInfoMessage(
            t('Item {{ itemName }} can no longer be bought.', { itemName: nonListableCartItem.product.fullName }),
            GtmMessageOriginType.cart,
        );
    }
};

const handleCartPromoCodeModifications = (
    promoCodeModifications: CartPromoCodeModificationsFragmentApi,
    t: Translate,
): void => {
    for (const nonApplicablePromoCode of promoCodeModifications.noLongerApplicablePromoCode) {
        showInfoMessage(
            t('The promo code {{ promoCode }} is no longer applicable.', { promoCode: nonApplicablePromoCode }),
            GtmMessageOriginType.cart,
        );
    }
};

const handleCartVoucherModifications = (
    voucherModifications: CartVoucherModificationsFragmentApi,
    t: Translate,
): void => {
    for (const noLongerApplicableVoucher of voucherModifications.noLongerApplicableVoucher) {
        showInfoMessage(
            t('The voucher {{ voucherCode }} is no longer applicable.', { voucherCode: noLongerApplicableVoucher }),
            GtmMessageOriginType.cart,
        );
    }
};

export const isServiceRestrictedWithGlobalServices = (
    productServiceUuid: string,
    globalServices: CartServiceFragmentApi[],
): boolean[] | string[] => {
    for (const service of globalServices) {
        if (service.restrictedProductService.length) {
            for (const restrictedProductService of service.restrictedProductService) {
                if (restrictedProductService.uuid === productServiceUuid) {
                    return [service.name, restrictedProductService.uuid];
                }
            }
        }
    }
    return [false, false];
};
