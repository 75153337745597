var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bb6ff28b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const dsn: string = publicRuntimeConfig.sentryDsn;
const environment: string = publicRuntimeConfig.sentryEnvironment;

Sentry.init({
    dsn: dsn,
    environment: environment,
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
        const error = hint.originalException;

        if (
            (typeof error === 'string' && error.match(/\[GraphQL] No result found for request./i) !== null) ||
            (error instanceof Error && error.message.match(/\[GraphQL] No result found for request./i) !== null) ||
            (error instanceof Error && error.message.match(/\[GraphQL] Token is not valid./i) !== null) ||
            (error instanceof Error && error.message.match(/\[Network] Error: Service Unavailable/i) !== null) ||
            (error instanceof Error && error.message.match(/Site Maintenance./i) !== null)
        ) {
            return null;
        }

        return event;
    },
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Ceneo plugin
        /ssl\.ceneo\.pl/i,
    ],
});
